<template>
  <div class="subnav d-flex justify-space-between align-center pt-3 px-5 pb-8 d-sm-none">
    <div class="font-w-7 rem-12 primary--text">{{ $t(currPage) }}</div>
    <div class="d-flex white--text">
      <div
        class="page-btn primary rem-0 d-flex justify-center align-center text-center can-click"
        :class="{'selected': item.text === currPage, 'mr-3 mr-sm-4': i !== pages.length - 1}"
        v-for="(item, i) in pages"
        :key="i"
        @click="$router.push({
          name: item.link,
          params: {
            lang: $store.state.locale,
            interestToken: $route.params.interestToken || $store.state.interestTokens[0].token,
            depositToken: $route.params.depositToken || $store.state.depositTokens[0].token
          }
        })"
      >
        {{ $t(item.text) }}
      </div>
    </div>
    <div v-if="$route.params.interestToken && !showPrevious" class="interest-token-list">
      <div class="d-flex align-center">
        <img
          class="mx-2 can-click"
          v-for="item in $store.state.interestTokens.filter(item => item.token !== $route.params.interestToken)"
          :key="item.token"
          :src="`${require(`@/assets/img/icon-polygon-${item.token}-disabled.svg`)}`"
          :alt="item.token"
          width="25px"
          @click="changeInterestToken(item)"
        >
      </div>
    </div>
    <slot v-else-if="customPreviousBtn" name="previousBtn" />
    <div v-else class="font-w-7 rem-1 darkGrey--text white previous-page-btn pl-5 pr-3 py-1 can-click" @click="$router.go(-1)">{{ $t('backToPreviousPage') }}</div>
    <div class="font-w-7 rem-1 white--text lock-btn pr-5 pl-3 py-1" :style="{background: $store.state.isMember ? '#33BA1D' : '#A70000'}">{{ $t($store.state.isMember ? 'unlock' : 'lock') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    currPage: String,
    showPrevious: {
      type: Boolean,
      default: false,
    },
    customPreviousBtn: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      pages: [
        {
          text: 'footerDeposit',
          link: 'Deposit'
        },
        {
          text: 'footerAsset',
          link: 'Asset'
        },
        {
          text: 'footerRule',
          link: 'Rule',
        },
        {
          text: 'tokenSwap',
          link: 'Swap',
        },
      ],
    }
  },
  methods: {
    changeInterestToken(item) {
      if (this.$route.name === 'Deposit') {
        this.$router.push({name: 'Deposit', params: {lang: this.$store.state.locale, interestToken: item.token, depositToken: this.$route.params.depositToken}})
      } else if (this.$route.name === 'Asset') {
        this.$router.push({name: 'Asset', params: {lang: this.$store.state.locale, interestToken: item.token}})
      }
    }
  }
}
</script>

<style lang="scss">
.subnav {
  margin: 0 -12px;
  position: relative;
  .page-btn {
    border: 1px solid #C9C9C9;
    box-shadow: 0px 0.8px 0.8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 40px;
    line-height: 14px;
    padding: 6px 4px;
    &.selected {
      border-color: var(--v-lightPrimary2-base) !important;
      color: var(--v-lightPrimary2-base) !important;
    }
  }
  .previous-page-btn, .lock-btn {
    position: absolute;
    height: 24px;
    bottom: -12px;
    box-shadow: 0px 0.8px 0.8px rgba(0, 0, 0, 0.25);
  }
  .interest-token-list {
    position: absolute;
    left: 20px;
    bottom: -17.5px;
  }
  .previous-page-btn {
    border-radius: 0 13px 13px 0;
    left: 0;
  }
  .lock-btn {
    right: 0;
    border-radius: 13px 0 0 13px;
  }
}
</style>